<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :labelInLocale="true"
      :params="_params"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "聯絡請求",
    };
  },

  computed: {
    _params() {
      if (this.$store.state.auth.currentUser.country_code) {
        return {
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      } else {
        return {};
      }
    },
  },

  data: () => ({
    modelName: "contact_request",
    showFields: ["created_at", "name", "email"],
  }),
};
</script>